import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const AnimatedImageSlideIn = ({
    source,
    title,
    direction = "left",
    className = "",
    speed = "fast",
    divClassName = "",
}) => {
    const [isSlideAnimationInProgress, setSlideAnimationInProgress] =
        useState(true);
    const [isInView, setIsInView] = useState(false);
    const [ref, inView] = useInView({
        threshold: 0.5, // Trigger animation when 50% of the image is in view
        triggerOnce: true, // Only trigger once
    });

    useEffect(() => {
        if (inView) {
            setIsInView(true);
            setSlideAnimationInProgress(true);
            // randomize the delay for the gentleBob animation to avoid all images bobbing at the same time
            // between 4.0 and 5.0 seconds
            var bobAfterSeconds = 2000;
            if (speed == "slow") {
                bobAfterSeconds = 4000;
            }
            const bobbingTimerSeconds =
                Math.floor(Math.random() * 1000) + bobAfterSeconds;

            const timer = setTimeout(() => {
                setSlideAnimationInProgress(false);
            }, bobbingTimerSeconds); // Duration for slideToX animation
            return () => clearTimeout(timer);
        }
    }, [inView]);

    return (
        <div ref={ref} className={`overflow-hidden ${divClassName}`}>
            <img
                src={source}
                alt={title}
                className={`${className} ${
                    isInView && isSlideAnimationInProgress
                        ? direction == "left"
                            ? speed == "slow"
                                ? "animate-slideToLeft"
                                : "animate-slideToLeftFast"
                            : speed == "slow"
                            ? "animate-slideToRight"
                            : "animate-slideToRightFast"
                        : "animate-gentleBob"
                } ${!isInView ? "hidden" : ""}`}
            />
        </div>
    );
};

export default AnimatedImageSlideIn;
